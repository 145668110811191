import "./face.css"
import { IFace, IFaceImage, IFaceText } from "../../Models/IBadge";
import FaceText from "./face-text";
import FaceImage from "./face-image";

type FaceChanged = (text: IFace) => void; 
export default function Face(props: {landscape: boolean, lanyard: boolean, face: IFace, onChanged?: FaceChanged}) {

    const faceTextChanged = (i: number, faceText: IFaceText) => {
        if(props.onChanged == null) return;

        props.face.texts[i] = faceText;
        props.onChanged(props.face);
    }

    const faceTextDeleted = (i: number) => {
        if(props.onChanged == null) return;

        props.face.texts.splice(i, 1);
        props.onChanged(props.face);
    }

    const faceImageChanged = (i: number, faceImage: IFaceImage) => {
        if(props.onChanged == null) return;

        props.face.images[i] = faceImage;
        props.onChanged(props.face);
    }

    const faceImageDeleted = (i: number) => {
        if(props.onChanged == null) return;

        props.face.images.splice(i, 1);
        props.onChanged(props.face);
    }

    return (
        <div className="face">
            <div className={props.landscape ? 'landscape' : 'portrait'} style={{position: 'relative', 
                //display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
            }}>
                {props.lanyard ? <div className="lanyard-hole" data-html2canvas-ignore></div> : null }

                <div className="face-image" style={{
                    backgroundImage: `url(${props.face.imageUrl})`,
                    backgroundColor: props.face.color || ''
                }}></div>

                {props.face.texts.map((text, i) => 
                    <FaceText 
                        key={i} 
                        text={text} 
                        onChanged={(x) => faceTextChanged(i, x)}
                        onDelete={() => faceTextDeleted(i)}
                        disabled={props.onChanged == null}
                    />
                )}

                {props.face.images.map((image, i) =>
                    <FaceImage 
                        key={i}
                        image={image}
                        onChanged={(x) => faceImageChanged(i, x)}
                        onDelete={() => faceImageDeleted(i)}
                        disabled={props.onChanged == null}
                    />
                )}
            </div>
        </div>
       
    )
}
