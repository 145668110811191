import { faBold, faItalic, faUnderline, faTrash, faUpDownLeftRight, faChevronDown, faRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Popover, PopoverTrigger, PopoverContent, ButtonGroup, DropdownSection, Textarea } from "@nextui-org/react";
import { useState, CSSProperties } from "react";
import { IFaceText } from "../../Models/IBadge";
import ColorPicker from "../menu/color-picker";
import Align from "../menu/align";
import Move from "../menu/move";
import Rotate from "../menu/rotate";

enum MenuState {
    Default,
    Move,
    Rotate,
    EditText
}

type FaceTextChanged = (text: IFaceText) => void; 
export default function FaceText(props: {text: IFaceText, disabled?: boolean, onChanged: FaceTextChanged, onDelete: any}) {
    const [selected, setSelected] = useState(false);
    const [menuState, setMenuState] = useState(MenuState.Default);

    const closed = () => {
        setMenuState(MenuState.Default)
        setSelected(false);
    }

    const toggleBold = () => props.onChanged({...props.text, bold: !props.text.bold})
    const toggleUnderline = () => props.onChanged({...props.text, underline: !props.text.underline})
    const toggleItalic = () => props.onChanged({...props.text, italic: !props.text.italic})
    const setText = (content: string) => {
        if(content == null || content == '') props.onDelete()
        else props.onChanged({...props.text, content})
    }
    const setFontSize = (fontSize: string) => props.onChanged({...props.text, fontSize})
    const setColor = (color: string) => props.onChanged({...props.text, color})

    const alignCenterHorizontally = () => props.onChanged({...props.text, xPercentage: 50, xTransform: 50})
    const setX = (xPercentage: number) => props.onChanged({...props.text, xPercentage, xTransform: 50})
    const alignLeft = () => props.onChanged({...props.text, xPercentage: 0, xTransform: 0})
    const alignRight = () => props.onChanged({...props.text, xPercentage: 100, xTransform: 100})
    const alignCenterVertically = () => props.onChanged({...props.text, yPercentage: 50, yTransform: 50})
    const setY = (yPercentage: number) => props.onChanged({...props.text, yPercentage, yTransform: 50})
    const alignTop = () => props.onChanged({...props.text, yPercentage: 0, yTransform: 0})
    const alignBottom = () => props.onChanged({...props.text, yPercentage: 100, yTransform: 100})
    const rotate = (rotation: number) => props.onChanged({...props.text, rotation})


    const bold = <Button color="secondary" isDisabled={props.disabled} isIconOnly onClick={toggleBold} variant={props.text.bold ? 'faded' : 'solid'}><FontAwesomeIcon icon={faBold}/></Button>;
    const italic = <Button color="secondary" isDisabled={props.disabled} isIconOnly onClick={toggleItalic} variant={props.text.italic ? 'faded' : 'solid'}><FontAwesomeIcon icon={faItalic}/></Button>;
    const underline = <Button color="secondary" isDisabled={props.disabled} isIconOnly onClick={toggleUnderline} variant={props.text.underline ? 'faded' : 'solid'}><FontAwesomeIcon icon={faUnderline}/></Button>;
    const deleteButton = <Button isDisabled={props.disabled} isIconOnly onClick={props.onDelete} color="danger"><FontAwesomeIcon icon={faTrash}/></Button>;
    const color = <ColorPicker color="secondary" value={props.text.color} onValueChange={setColor}/>
    const font = <Dropdown isDisabled={props.disabled}>
                    <DropdownTrigger>
                        <Button color="secondary" isIconOnly>{props.text.fontSize || 'F'}</Button>
                    </DropdownTrigger>
                    <DropdownMenu onAction={key => setFontSize(key.toString())}>
                        <DropdownSection title="Font Size">
                            {fontSizes.map(fontSize => (
                                <DropdownItem key={fontSize} value={fontSize}>{fontSize}</DropdownItem>
                            ))}
                        </DropdownSection>
                    </DropdownMenu>
                </Dropdown>;


    const styles: CSSProperties = {
        whiteSpace: 'pre-wrap',
        border: selected ? '1px dashed black' : '',
        padding: '5px',
        fontSize: props.text.fontSize ? `${props.text.fontSize}px` : 'unset',
        color: props.text.color || 'black',
        fontWeight: props.text.bold ? 'bold' : 'normal',
        textDecoration: props.text.underline ? 'underline' : 'none',
        fontStyle: props.text.italic ? 'italic' : 'unset'
    }

    return (
        <div style={{position: 'absolute', 
            top: `${props.text.yPercentage}%`, left: `${props.text.xPercentage}%`,
            transform: `translate(-${props.text.xTransform}%, -${props.text.yTransform}%) rotate(${props.text.rotation || 0}deg)`,
        }}
        onDoubleClick={e => e.stopPropagation()} // prevents parent on double click from triggering
        >
            {menuState == MenuState.EditText ? (
                <Textarea 
                    value={props.text.content} 
                    onValueChange={setText} 
                    onBlur={closed}
                    maxRows={10000}
                />
            ): (
                <>
                {props.disabled && (
                    <div style={styles}>
                        {props.text.content}
                    </div>
                )}

                {!props.disabled && (
                    <Popover showArrow onOpenChange={setSelected} onClose={closed}>
                        <PopoverTrigger>
                            <div style={styles} onDoubleClick={() => setMenuState(MenuState.EditText)}>
                                {props.text.content}
                            </div>
                        </PopoverTrigger>
                        <PopoverContent>
                            {menuState == MenuState.Default && (
                                <div style={{display: 'flex', gap: '15px', flexWrap: 'wrap'}}>
                                    <div>
                                        <p className="text-tiny text-default-500 mb-1">Style</p>
                                        <ButtonGroup className="d-big">
                                            {bold}
                                            {italic}
                                            {underline}
                                        </ButtonGroup>
                                        <ButtonGroup className="d-small">
                                            <Dropdown>
                                                <DropdownTrigger>
                                                    <Button color="secondary" isIconOnly><FontAwesomeIcon icon={faChevronDown}/></Button>
                                                </DropdownTrigger>
                                                <DropdownMenu>
                                                    <DropdownItem onClick={toggleBold} startContent={<FontAwesomeIcon icon={faBold}/>}>Bold</DropdownItem>
                                                    <DropdownItem onClick={toggleItalic} startContent={<FontAwesomeIcon icon={faItalic}/>}>Italic</DropdownItem>
                                                    <DropdownItem onClick={toggleUnderline} startContent={<FontAwesomeIcon icon={faUnderline}/>}>Underline</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </ButtonGroup>
                                    </div>

                                    <div>
                                        <p className="text-tiny text-default-500 mb-1">Font</p>
                                        <ButtonGroup>
                                            {font}
                                            {color}
                                        </ButtonGroup>
                                    </div>

                                    <div>
                                        <p className="text-tiny text-default-500 mb-1">Align</p>
                                        <Align color="secondary" 
                                            alignCenterHorizontally={alignCenterHorizontally} 
                                            alignCenterVertically={alignCenterVertically} 
                                            alignLeft={alignLeft}
                                            alignRight={alignRight}
                                            alignTop={alignTop}
                                            alignBottom={alignBottom}
                                        />
                                    </div>

                                    <div>
                                        <p className="text-tiny text-default-500 mb-1">Move</p>
                                        <Button color="secondary" isIconOnly onClick={() => setMenuState(MenuState.Move)}><FontAwesomeIcon icon={faUpDownLeftRight} /></Button>
                                    </div>

                                    <div>
                                        <p className="text-tiny text-default-500 mb-1">Rotate</p>
                                        <Button color="secondary" isIconOnly onClick={() => setMenuState(MenuState.Rotate)}><FontAwesomeIcon icon={faRotate} /></Button>
                                    </div>

                                    <div>
                                        <p className="text-tiny text-default-500 mb-1">Delete</p>
                                        {deleteButton}
                                    </div>
                                </div>
                            )}

                            {menuState == MenuState.Move && (
                                <div>
                                    <p className="text-tiny text-default-500 mb-1">Move</p>
                                    <Move 
                                        color="secondary"
                                        xPercentage={props.text.xPercentage}
                                        onXChanged={setX}
                                        yPercentage={props.text.yPercentage}
                                        onYChanged={setY}
                                    />
                                </div>
                            )}

                            {menuState == MenuState.Rotate && (
                                <div>
                                    <p className="text-tiny text-default-500 mb-1">Rotate</p>
                                    <Rotate 
                                        color="secondary"
                                        value={props.text.rotation}
                                        onChange={rotate}
                                    />
                                </div>
                            )}
                        </PopoverContent>
                    </Popover>
                )}
                </>
            )}
        </div>
    )
}

const fontSizes = [8, 12, 16, 20, 26, 32, 40, 48, 58, 68, 80, 92]