import { Input, Slider, Spacer } from "@nextui-org/react";

type PositionChanged = (position: number) => void;
export default function Move(props: {
    color: any,
    xPercentage: number,
    onXChanged: PositionChanged,
    yPercentage: number,
    onYChanged: PositionChanged
}) {
    const setX = (value: string) => {
        let parsedValue = parseInt(value);

        if (isNaN(parsedValue)) {
            parsedValue = 0;
        }

        props.onXChanged(parsedValue);
    }

    const setY = (value: string) => {
        let parsedValue = parseInt(value);

        if (isNaN(parsedValue)) {
            parsedValue = 0;
        }

        props.onYChanged(parsedValue);
    }

    return (
       <div>
            <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', alignItems: 'center', gap: '10px'}}>
                <Input 
                    type="number" 
                    value={props.xPercentage.toString()} 
                    onValueChange={setX} 
                    startContent={<div>X</div>} 
                    endContent={<div>%</div>} 
                />
                <Slider   
                    color={props.color}
                    step={1} 
                    maxValue={100} 
                    minValue={0} 
                    value={props.xPercentage}
                    onChange={v => props.onXChanged(v as number)}
                    showTooltip
                />
            </div>
            <Spacer y={2} />
            <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', alignItems: 'center', gap: '10px'}}>
                <Input 
                    type="number"
                    value={props.yPercentage.toString()} 
                    onValueChange={setY} 
                    startContent={<div>Y</div>} 
                    endContent={<div>%</div>} 
                />
                <Slider   
                    color={props.color}
                    step={1} 
                    maxValue={100} 
                    minValue={0} 
                    value={props.yPercentage}
                    onChange={v => props.onYChanged(v as number)}
                    showTooltip
                />  
            </div>
       </div>
    )
}