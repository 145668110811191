import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.css'
import Home from "./Pages/Home"
import CheckoutSuccess from "./Pages/CheckoutSuccess"
import { NextUIProvider } from '@nextui-org/system';
import Nav from './Components/nav';

const router = createBrowserRouter([
    {
      path: "/",
      Component: Home,
      //errorElement: <NotFound />
    },
    {
        path: 'success',
        Component: CheckoutSuccess
    }
]);

export default function App() {
    return (
        <NextUIProvider>
            <div>
                <Nav />
                <div id="container">
                    <RouterProvider router={router} />
                </div>
            </div>
        </NextUIProvider>
    )
}
