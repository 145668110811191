import { faImage } from "@fortawesome/free-regular-svg-icons";
import { faFont, faTrash, faMountainSun } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover, PopoverTrigger, Button, PopoverContent, Spacer } from "@nextui-org/react";
import { useContext } from "react";
import { FaceContext } from "../Context/face-context";
import { loadImage } from "../utils";
import ColorPicker from "./menu/color-picker";

export default function FaceControls() {
    const {face, setFace} = useContext(FaceContext);

    const setImage = () => {
        loadImage((imageUrl: string) => {
            setFace({
                ...face,
                imageUrl
            })
        })
    };

    const setColor = (color: string) => {
        console.log(color)
        setFace({
            ...face,
            color
        })
    }

    const resetBackground = () => {
        setFace({
            ...face,
            imageUrl: null,
            color: null
        })
    }

    const addImage = () => {
        loadImage((url: string) => {
            face.images.push({
                url,
                xPercentage: 50,
                xTransform: 50,
                yPercentage: 50,
                yTransform: 50,
                width: 50
            })
            setFace({...face});
        })   
    }

    const addText = () => {
        face.texts.push({
            content: 'Text',
            xPercentage: 50,
            xTransform: 50,
            yPercentage: 50,
            yTransform: 50,
        })
        setFace({...face});
    }

    const reset = () => {
        setFace({texts: [], images: []})
    }

    return (
        <div style={{display: 'flex', gap: '10px'}}>
            <Popover>
                <PopoverTrigger>
                    <Button isIconOnly color="default"><FontAwesomeIcon icon={faMountainSun}/></Button>
                </PopoverTrigger>
                <PopoverContent>
                    <div className="text-tiny text-default-500 text-start">Background</div>
                    <Spacer y={1} />
                    <div style={{display: 'flex', gap: '5px'}}>
                        <Button onClick={setImage} isIconOnly startContent={<FontAwesomeIcon icon={faImage}/>} />
                        <ColorPicker value={face.color} onValueChange={setColor} />
                        <Button 
                            onClick={resetBackground} 
                            isIconOnly 
                            startContent={<FontAwesomeIcon icon={faTrash}/>} 
                            color="danger"
                        />
                    </div>
                </PopoverContent>
            </Popover>

            <Button 
                startContent={<FontAwesomeIcon icon={faImage}/>}
                onClick={addImage}
                color="primary"
                isIconOnly
            >
            </Button>

            <Button 
                startContent={<FontAwesomeIcon icon={faFont}/>}
                onClick={addText}
                color="secondary"
                isIconOnly
            >
            </Button>

            {/* Todo make modal */}
            <Button 
                startContent={<FontAwesomeIcon icon={faTrash}/>}
                color="danger"
                onClick={reset}
                isIconOnly
            >
            </Button>
        </div>
    )
}