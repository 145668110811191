import html2canvas from "html2canvas";

export function loadFile(callback: any, fileType: string) {
    var input = document.createElement('input');
    input.type = 'file';
    input.accept = fileType;

    input.onchange = (e: any) => { 
        if(e.target?.files == null) return;
        var file = e.target.files[0]; 
        callback(file);
    }

    input.click();
}

export function loadImage(callback: any) {
    loadFile((file: any) => {
         var reader = new FileReader();
         reader.readAsDataURL(file);
 
         reader.onload = readerEvent => {
             callback(readerEvent!.target!.result!.toString());
         }
    }, "image/png, image/jpeg");
 }

export const getImages = async () => {
    const cardFaces =  document.getElementById("checkout-container")!.getElementsByClassName('face')!;
    const images: Blob[] = [];
    for(let i = 0; i < cardFaces.length; i++) {
        const cardFace = cardFaces[i];
        if(cardFace instanceof HTMLDivElement) {
            const canvas = await html2canvas(cardFace, {
                scale: 5, // scale up to increase image quality
            });
            const blob: Blob | null = await new Promise(resolve => canvas.toBlob(resolve));
            images.push(blob!);
        }
    }

    return images;
}