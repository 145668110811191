import { createContext } from "react";
import { DisplayOption, IOptions } from "../Models/IOptions";

type OptionsSetter = (options: IOptions) => void;

export const OptionsContext = createContext<{
    options: IOptions,
    setOptions: OptionsSetter
}>({
    options: {
        display: DisplayOption.Front
    },
    setOptions: () => {}
})