import { Avatar, Link, Navbar, NavbarBrand, NavbarContent, NavbarItem, Image } from "@nextui-org/react";

export default function Nav() {
    return (
        <Navbar isBordered maxWidth="2xl">
            <NavbarBrand>
                <Image src="/logo.png" width={36}/>
                <p className="font-bold text-inherit ml-2">Build A Badge</p>
            </NavbarBrand>

            <NavbarContent justify="end">
                <NavbarItem>
                    <Link href="mailto:support@build-a-badge.com">Support</Link>
                </NavbarItem>
            </NavbarContent>
        </Navbar>
    )
}