import { faAlignCenter, faAlignJustify, faAlignLeft, faAlignRight, faArrowsDownToLine, faArrowsUpToLine, faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonGroup, Button, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, DropdownSection } from "@nextui-org/react";

export default function Align(props: {
    color: any,
    alignCenterHorizontally: any,
    alignLeft: any,
    alignRight: any,
    alignCenterVertically: any,
    alignTop: any,
    alignBottom: any
}) {
    return (
        <ButtonGroup>
            <Dropdown>
                <DropdownTrigger>
                    <Button color={props.color} isIconOnly><FontAwesomeIcon icon={faAlignJustify}/></Button>
                </DropdownTrigger>
                <DropdownMenu closeOnSelect={false}>
                    <DropdownSection title="Horizontal">
                        <DropdownItem onClick={props.alignCenterHorizontally} startContent={<FontAwesomeIcon icon={faAlignCenter}/>}>
                            Align Center
                        </DropdownItem>
                        <DropdownItem onClick={props.alignLeft} startContent={<FontAwesomeIcon icon={faAlignLeft}/>}>
                            Align Left
                        </DropdownItem>
                        <DropdownItem onClick={props.alignRight} startContent={<FontAwesomeIcon icon={faAlignRight}/>}>
                            Align Right
                        </DropdownItem>
                    </DropdownSection>
                    
                    <DropdownSection title="Vertical">
                        <DropdownItem onClick={props.alignCenterVertically} startContent={<FontAwesomeIcon icon={faAlignCenter}/>}>
                            Align Center
                        </DropdownItem>
                        <DropdownItem onClick={props.alignTop} startContent={<FontAwesomeIcon icon={faArrowsUpToLine}/>}>
                            Align Top
                        </DropdownItem>
                        <DropdownItem onClick={props.alignBottom} startContent={<FontAwesomeIcon icon={faArrowsDownToLine}/>}>
                            Align Bottom
                        </DropdownItem>
                    </DropdownSection>
                </DropdownMenu>
            </Dropdown>
        </ButtonGroup>
    )
}