import { createContext } from "react";
import { IFace } from "../Models/IBadge";

type FaceSetter = (face: IFace) => void;

export const FaceContext = createContext<{
    face: IFace,
    setFace: FaceSetter
}>({
    face: {
        texts: [],
        images: []
    },
    setFace: () => {}
});