import "./badge-editor.css"
import { useContext } from "react";
import { OptionsContext } from "../Context/options-context";
import { DisplayOption } from "../Models/IOptions";
import { BadgeContext } from "../Context/badge-context";
import { IFace } from "../Models/IBadge";
import { FaceContext } from "../Context/face-context";
import FaceEditor from "./face-editor";
import { Button, Spacer } from "@nextui-org/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateRight } from "@fortawesome/free-solid-svg-icons";

export default function BadgeEditor() {
    const {options, setOptions} = useContext(OptionsContext);
    const {badge, setBadge} = useContext(BadgeContext);

    const setFrontFace = (face: IFace) => setBadge({...badge, front: face})
    const setBackFace = (face: IFace) => setBadge({...badge, back: face})
    const toggleDisplay = () => setOptions({...options, display: options.display == DisplayOption.Front ? DisplayOption.Back : DisplayOption.Front});

    const faceHeader = (name: string) => {
        return (
                <>
                <Spacer y={3} />
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div className="text-center text-sm font-bold uppercase">
                        {name}
                    </div>

                    {options.display != DisplayOption.Both && (
                        <div style={{position: 'relative', display: 'flex'}}>
                            <Button 
                                onClick={toggleDisplay} 
                                isIconOnly 
                                startContent={<FontAwesomeIcon icon={faRotateRight}/>}
                                style={{position: 'absolute', margin: 'auto', top: 0, left: '10px', bottom: 0, right: 0}}
                                variant="flat"
                            />
                        </div>
                    )}
                </div>
                <Spacer y={5} />
                </>
        )
    }

    if(options.display == DisplayOption.Front) {
        return (
            <FaceContext.Provider value={{face: badge.front, setFace: setFrontFace}}>
                <div>
                    {faceHeader("Front")}
                    <FaceEditor />
                </div>
            </FaceContext.Provider>
        )
    }

    if(options.display == DisplayOption.Back) {
        return (
            <FaceContext.Provider value={{face: badge.back, setFace: setBackFace}}>
                <div>
                    {faceHeader("Back")}
                    <FaceEditor />
                </div>
            </FaceContext.Provider>
        )
    }

    return (
        <div id='multi-face'>
            <FaceContext.Provider value={{face: badge.front, setFace: setFrontFace}}>
                <div>
                    {faceHeader("Front")}
                    <FaceEditor />
                </div>
            </FaceContext.Provider>

            <FaceContext.Provider value={{face: badge.back, setFace: setBackFace}}>
                <div>
                    {faceHeader("Back")}
                    <FaceEditor />
                </div>
            </FaceContext.Provider>
        </div>
    )
}