import { createContext } from "react";
import { IBadge } from "../Models/IBadge";

type BadgeSetter = (badge: IBadge) => void;

export const BadgeContext = createContext<
{
    badge: IBadge,
    setBadge: BadgeSetter
}
>({
    badge: {
        landscape: false,
        lanyard: false,
        front: {
            texts: [],
            images: [],
        },
        back: {
            texts: [],
            images: []
        }
    },
    setBadge: () => {}
});