import { Card, Spacer } from "@nextui-org/react";
import { useContext } from "react";
import { BadgeContext } from "../Context/badge-context";
import { FaceContext } from "../Context/face-context";
import Face from "./face/face";
import FaceControls from "./face-controls";

export default function FaceEditor() {
    const {badge} = useContext(BadgeContext);
    const {face, setFace} = useContext(FaceContext);

    const addText = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();

        face.texts.push({
            content: 'Text',
            xPercentage: 50,
            xTransform: 50,
            yPercentage: 50,
            yTransform: 50
        })
        setFace({...face});
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <div onDoubleClick={addText}>
                <Card>
                    <Face landscape={badge.landscape} lanyard={badge.lanyard} face={face} onChanged={setFace} />
                </Card>
            </div>
            
            <Spacer y={3} />
            <FaceControls />
        </div>
    )
}