import { Button, Popover, PopoverContent, PopoverTrigger } from "@nextui-org/react";
import { IFaceImage } from "../../Models/IBadge";
import { faExpand, faRotate, faTrash, faUpDownLeftRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSProperties, useState } from "react";
import Align from "../menu/align";
import Move from "../menu/move";
import Rotate from "../menu/rotate";
import Resize from "../menu/resize";

enum MenuState {
    Default,
    Move,
    Rotate,
    Resize
}

type FaceImageChanged = (image: IFaceImage) => void; 
export default function FaceImage(props: {
    image: IFaceImage, onChanged: FaceImageChanged, onDelete: any,
    disabled?: boolean
}) {
    const [menuState, setMenuState] = useState(MenuState.Default);

    const closed = () => {
        setMenuState(MenuState.Default)
    }

    const alignCenterHorizontally = () => props.onChanged({...props.image, xPercentage: 50, xTransform: 50})
    const setX = (xPercentage: number) => props.onChanged({...props.image, xPercentage, xTransform: 50})
    const alignLeft = () => props.onChanged({...props.image, xPercentage: 0, xTransform: 0})
    const alignRight = () => props.onChanged({...props.image, xPercentage: 100, xTransform: 100})
    const alignCenterVertically = () => props.onChanged({...props.image, yPercentage: 50, yTransform: 50})
    const setY = (yPercentage: number) => props.onChanged({...props.image, yPercentage, yTransform: 50})
    const alignTop = () => props.onChanged({...props.image, yPercentage: 0, yTransform: 0})
    const alignBottom = () => props.onChanged({...props.image, yPercentage: 100, yTransform: 100})
    const setWidth = (width: number) => props.onChanged({...props.image, width});
    const rotate = (rotation: number) => props.onChanged({...props.image, rotation})

    const deleteButton = <Button isIconOnly onClick={props.onDelete} color="danger"><FontAwesomeIcon icon={faTrash}/></Button>;

    const styles: CSSProperties = {
        // both width properties have to be set here
        minWidth: `${props.image.width}px`, width: `${props.image.width}px`
    }

    return (
        <div style={{position: 'absolute', 
            top: `${props.image.yPercentage}%`, left: `${props.image.xPercentage}%`,
            transform: `translate(-${props.image.xTransform}%, -${props.image.yTransform}%) rotate(${props.image.rotation || 0}deg)`,
        }}
        onDoubleClick={e => e.stopPropagation()} // prevents parent on double click from triggering
        >
            {props.disabled && (
                <img src={props.image.url} style={styles} /> 
            )}

            {!props.disabled && (
                <Popover showArrow onClose={closed}>
                    <PopoverTrigger>
                        <img src={props.image.url} style={styles} /> 
                    </PopoverTrigger>
                    <PopoverContent>
                        {menuState == MenuState.Default && (
                            <div style={{display: 'flex', gap: '15px', flexWrap: 'wrap'}}>
                                <div>
                                    <p className="text-tiny text-default-500 mb-1">Resize</p>
                                    <Button color="primary" isIconOnly onClick={() => setMenuState(MenuState.Resize)}><FontAwesomeIcon icon={faExpand} /></Button>
                                </div>

                                <div>
                                    <p className="text-tiny text-default-500 mb-1">Align</p>
                                    <Align color="primary" 
                                        alignCenterHorizontally={alignCenterHorizontally} 
                                        alignCenterVertically={alignCenterVertically}
                                        alignLeft={alignLeft}
                                        alignRight={alignRight}
                                        alignTop={alignTop}
                                        alignBottom={alignBottom}
                                    />
                                </div>

                                <div>
                                    <p className="text-tiny text-default-500 mb-1">Move</p>
                                    <Button color="primary" isIconOnly onClick={() => setMenuState(MenuState.Move)}><FontAwesomeIcon icon={faUpDownLeftRight} /></Button>
                                </div>

                                <div>
                                    <p className="text-tiny text-default-500 mb-1">Rotate</p>
                                    <Button color="primary" isIconOnly onClick={() => setMenuState(MenuState.Rotate)}><FontAwesomeIcon icon={faRotate} /></Button>
                                </div>

                                <div>
                                    <p className="text-tiny text-default-500 mb-1">Delete</p>
                                    {deleteButton}
                                </div>
                            </div>
                        )}

                        {menuState == MenuState.Move && (
                            <div>
                                <p className="text-tiny text-default-500 mb-1">Move</p>
                                <Move 
                                    color="primary"
                                    xPercentage={props.image.xPercentage}
                                    onXChanged={setX}
                                    yPercentage={props.image.yPercentage}
                                    onYChanged={setY}
                                />
                            </div>
                        )}

                        {menuState == MenuState.Rotate && (
                            <div>
                                <p className="text-tiny text-default-500 mb-1">Rotate</p>
                                <Rotate 
                                    color="primary"
                                    value={props.image.rotation}
                                    onChange={rotate}
                                />
                            </div>
                        )}

                        {menuState == MenuState.Resize && (
                            <div>
                                <p className="text-tiny text-default-500 mb-1">Resize</p>
                                <Resize 
                                    value={props.image.width}
                                    onChange={setWidth}
                                />
                            </div>
                        )}
                    </PopoverContent>
                </Popover>
            )}
        </div>
    )
}