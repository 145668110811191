import { faFill } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@nextui-org/react";

export default function ColorPicker(props: {value?: string | null, onValueChange: any, color?: any}) {
    return (
        <Button isIconOnly color={props.color || 'default'}>
            <div style={{position: 'relative', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: "center"}}>
                <FontAwesomeIcon  icon={faFill} style={{color: props.value || ''}}/>

                <input type="color" value={props.value || ''} onChange={e => props.onValueChange(e.target.value)} style={{opacity: 0, position: 'absolute', left: 0, top: 0, width: '100%', height: '100%'}} />
            </div></Button>
    )
}