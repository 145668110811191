import { Input } from "@nextui-org/react";

type SizeChanged = (size: number) => void;
export default function Resize(props: {
    value: number,
    onChange: SizeChanged
}) {
    return (
        <div>
            <Input 
                type="number" 
                value={props.value.toString()} 
                onValueChange={v => props.onChange(parseInt(v))}
                endContent={<div>px</div>}
            />
        </div>
    )
}