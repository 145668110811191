import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, useDisclosure } from "@nextui-org/react";
import { useContext, useState } from "react";
import { BadgeContext } from "../Context/badge-context";
import Face from "./face/face";
import { getImages } from "../utils";

export default function Checkout() {
    const {badge} = useContext(BadgeContext);
    const {isOpen, onOpen, onClose} = useDisclosure();
    const [isLoading, setIsLoading] = useState(false);

    const startCheckout = async () => {
        try {
            setIsLoading(true);

            var images = await getImages();

            const formData = new FormData();
            formData.append("badge", JSON.stringify(badge));
            formData.append("front", images[0]);
            formData.append("back", images[1]);

            var res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/start-checkout`, {
                method: 'POST',
                body: formData
            });

            if(!res.ok) throw new Error();

            var resBody = await res.json();

            window.location = resBody.url;
        } catch {
            // todo
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div>
            <Button 
                startContent={<FontAwesomeIcon icon={faShoppingCart}/>}
                color="primary"
                onClick={onOpen}
                fullWidth
                size="lg"
            >
                Checkout
            </Button>

            <Modal isOpen={isOpen} onClose={onClose} placement="center"  size="3xl" scrollBehavior="inside">
                <ModalContent>
                    <ModalHeader>Checkout</ModalHeader>
                    <ModalBody>
                        <blockquote className="text-center text-tiny border px-4 py-3 rounded-xl [&>p]:m-0 border-warning-100 bg-warning-50/20 my-2">
                            Build A Badge reserves the right to deny badges that resemble official government identification, contain illicit content, or for any other reason we deem. 
                            If a badge is denied, a refund will be issued. 
                        </blockquote>

                        <BadgePreviewer />
                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={onClose} color="danger">Cancel</Button>

                        <Button 
                            startContent={<FontAwesomeIcon icon={faShoppingCart}/>}
                            color="primary"
                            onClick={startCheckout}
                            isLoading={isLoading}
                        >
                            Order
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    )
}

export function BadgePreviewer() {
    const {badge} = useContext(BadgeContext);

    return (
        <div id="checkout-container" style={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap', alignItems: 'center', gap: '20px'}}>
            <Card>
                <Face landscape={badge.landscape} lanyard={badge.lanyard} face={badge.front} />
            </Card>
            <Card>
                <Face landscape={badge.landscape} lanyard={badge.lanyard} face={badge.back} />
            </Card>
        </div>
    )
}