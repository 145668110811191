import './Home.css'
import { Accordion, AccordionItem, Card, CardBody, Spacer } from '@nextui-org/react';
import { useState } from 'react';
import { IBadge } from '../Models/IBadge';
import { BadgeContext } from '../Context/badge-context';
import { OptionsContext } from '../Context/options-context';
import { IOptions, DisplayOption } from '../Models/IOptions';
import Options from '../Components/badge-options';
import BadgeEditor from '../Components/badge-editor';
import Help from '../Components/help';
import Checkout from '../Components/checkout';


export default function App() {
    const [badge, setBadge] = useState<IBadge>({
        landscape: false,
        lanyard: false,
        front: {
            texts: [],
            images: [], 
        },
        back: {
            texts: [],
            images: [], 
        }
    });

    const [options, setOptions] = useState<IOptions>({
        display: DisplayOption.Front
    })

    return(
        <BadgeContext.Provider value={{badge, setBadge}}>
            <OptionsContext.Provider value={{options, setOptions}}>
                <div id="home-container">
                    <Card className='d-big'>
                        <CardBody>
                            <Options />
                            <Spacer y={10} />
                            <Checkout />

                            <Spacer y={10} />
                            <Help />
                        </CardBody>
                    </Card>

                    <Accordion className='d-small' variant='shadow'>
                        <AccordionItem title="Options" subtitle="Expand for options">
                            <Options />
                            <Help />
                        </AccordionItem>
                    </Accordion>

                    <div className='border px-4 py-3 rounded-xl border-default-300 bg-foreground-50/20'>
                        <BadgeEditor />
                    </div>

                    <div className='d-small'>
                        <Card>
                            <CardBody>
                                <Checkout />
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </OptionsContext.Provider>
        </BadgeContext.Provider>
    )
}