import { faImage } from "@fortawesome/free-regular-svg-icons";
import { faFont, faMountainSun, faRotateRight, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionItem, Button, Spacer } from "@nextui-org/react";

export default function Help() {
    return (
        <div>
            <Accordion selectionMode="multiple">
                <AccordionItem title={<div className="font-bold uppercase">How To</div>}>

                    <Accordion variant="bordered">
                        <AccordionItem title="Basics">
                            <div>
                                Click

                                <Button 
                                    startContent={<FontAwesomeIcon icon={faRotateRight}/>}
                                    isIconOnly
                                    className="mx-1"
                                    size="sm"
                                >
                                </Button>

                                <span className="font-semibold">above</span> the badge to switch sides
                            </div>

                            <Spacer y={2} />

                            <div>
                                Click 

                                <Button 
                                    startContent={<FontAwesomeIcon icon={faTrash}/>}
                                    color="danger"
                                    isIconOnly
                                    className="mx-1"
                                    size="sm"
                                >
                                </Button>

                                <span className="font-semibold">below</span> the badge to reset the current side of badge
                            </div>
                        </AccordionItem>
                        <AccordionItem title="Edit Background">
                            <div>
                                Click
                                <Button 
                                    startContent={<FontAwesomeIcon icon={faMountainSun}/>}
                                    color="default"
                                    isIconOnly
                                    size="sm"
                                    className="mx-1"
                                >
                                </Button>
                                for available background options
                            </div>
                            <div className="text-tiny text-default-500">Image and Color</div>
                        </AccordionItem>
                        <AccordionItem title="Add/Edit Images">
                            <div>
                                Click
                                <Button 
                                    startContent={<FontAwesomeIcon icon={faImage}/>}
                                    color="primary"
                                    isIconOnly
                                    className="mx-1"
                                    size="sm"
                                >
                                </Button>
                                to add an image to the current side of the badge
                            </div>

                            <Spacer y={3} />

                            Select the added image for available options 

                            <div className="text-tiny text-default-500">Resizing, Aligning, Moving, and Rotating</div>
                        </AccordionItem>

                        <AccordionItem title="Add/Edit Text">
                            <div>
                                Click
                                <Button 
                                    startContent={<FontAwesomeIcon icon={faFont}/>}
                                    color="secondary"
                                    isIconOnly
                                    className="mx-1"
                                    size="sm"
                                >
                                </Button>
                                to add text to the current side of the badge
                            </div>

                            <Spacer y={3} />
                            Double click the added text to change the value 

                            <Spacer y={3} />
                            Select the added text for available options 
                            <div className="text-tiny text-default-500">Styling, Font, Aligning, Moving, and Rotating</div>
                        </AccordionItem>
                    </Accordion>                    
                </AccordionItem>
                <AccordionItem title={<div className="font-bold uppercase">Badge Specs</div>}>
                    <div>
                        Badges are the same size as a credit card 
                        <div className="text-tiny text-default-500">3.375 inches wide by 2.125 inches high</div>
                    </div>

                    <Spacer y={2} />

                    <div>
                        Badges do not have a magnetic strip.
                    </div>
                </AccordionItem>
                <AccordionItem title={<div className="font-bold uppercase">Uses</div>}>
                    <div>
                        Build A Badge should be used to create badges for work or parody.
                        <br />
                        <span className="text-default-500">Examples: McLovin, Employee Badge</span>

                        <Spacer y={2} />

                        It should <span className="font-bold">not</span> be used for official government identification or illicit content. 
                        Doing so may result in the authorities being contacted. 
                    </div>
                </AccordionItem>
            </Accordion>
        </div>
    )
}