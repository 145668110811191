import { faRotateLeft, faRotateRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonGroup, Slider } from "@nextui-org/react";

type RotationChanged = (rotation: number) => void;
export default function Rotate(props: {
    color: any,
    value?: number | null,
    onChange: RotationChanged
}) {
    const right = () => {
        emit((props.value || 0) + 90);
    }

    const left = () => {
        emit((props.value || 0) - 90);
    }

    const emit = (rotation: number) => {
        if(rotation < 0) rotation = 0;
        if(rotation > 360) rotation = 360
        props.onChange(rotation);
    }

    return (
        <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
            <ButtonGroup>
                <Button color={props.color} isIconOnly onClick={left}><FontAwesomeIcon icon={faRotateLeft}/></Button>
                <Button color={props.color} isIconOnly onClick={right}><FontAwesomeIcon icon={faRotateRight}/></Button>
            </ButtonGroup>

            <Slider   
                color={props.color}
                step={1} 
                maxValue={360} 
                minValue={0} 
                value={props.value || 0}
                onChange={v => props.onChange(v as number)}
                style={{width: '150px'}}
                showTooltip
            />
        </div>
    )
}