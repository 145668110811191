import { Checkbox, CheckboxGroup, Input, Spacer } from "@nextui-org/react";
import { useContext } from "react";
import { BadgeContext } from "../Context/badge-context";
import { OptionsContext } from "../Context/options-context";
import { DisplayOption } from "../Models/IOptions";

export default function Options() {
    const {badge, setBadge} = useContext(BadgeContext);
    const {options, setOptions} = useContext(OptionsContext);

    const setName = (name: string) => setBadge({...badge, name});
    
    const getValueForCheckboxes = () => {
        const selected = [];
        if(badge.landscape) selected.push('landscape');
        if(badge.lanyard) selected.push('lanyard');
        if(options.display == DisplayOption.Both) selected.push('display-both');
        return selected;
    }

    const updateOptions = (x: string[]) => {
        setBadge({
            ...badge,
            landscape: x.includes('landscape'),
            lanyard: x.includes('lanyard')
        })

        setOptions({
            ...options,
            display: x.includes('display-both') ? DisplayOption.Both : options.display == DisplayOption.Both ? DisplayOption.Front : options.display
        })
    }


    return (
        <div>
            <div>
                <Input 
                    value={badge.name} 
                    onValueChange={setName} 
                    label="Badge Name (optional)"
                />
            </div>

            <Spacer y={3} />

            <CheckboxGroup
                color="warning"
                value={getValueForCheckboxes()}
                onValueChange={updateOptions}
            >
                <Checkbox value="landscape">
                    Landscape
                    <br />
                    <span className="text-tiny text-default-500">Change orientation to landscape</span>
                </Checkbox>
                <Checkbox value="lanyard">
                    Lanyard
                    <br />
                    <span className="text-tiny text-default-500">Add hole for a lanyard</span>
                </Checkbox>
                <Checkbox value="display-both">
                    Show Both Sides
                    <br />
                    <span className="text-tiny text-default-500">Show front and back at the same time</span>
                </Checkbox>
            </CheckboxGroup>
        </div>
    )
}